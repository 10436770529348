import React from 'react';
import ServiceComponent from '../components/ServiceComponent';

const Montaz = () => {
const servicesData = [
  {
    title: 'Montaż pomp ciepła',
    descriptionPoints: [
      'Profesjonalny montaż pomp ciepła zgodny z normami bezpieczeństwa.',
      'Dobór i instalacja efektywnych pomp ciepła dostosowanych do potrzeb klienta.',
      'Serwis i konserwacja pomp ciepła dla utrzymania ich optymalnej wydajności.',
    ],
    image:'https://imageselektryk.s3.eu-central-1.amazonaws.com/mpc.webp'
  },
  {
    title: 'Montaż systemów klimatyzacji',
    descriptionPoints: [
      'Montaż nowoczesnych systemów klimatyzacji dla komfortowego klimatu w pomieszczeniach.',
      'Dobór i instalacja odpowiednich jednostek klimatyzacyjnych dostosowanych do budynku.',
      'Serwis i konserwacja systemów klimatyzacji dla zachowania ich sprawności.',
      
    ],
    image:'https://imageselektryk.s3.eu-central-1.amazonaws.com/mk.webp'
  },
  {
    title: 'Montaż instalacji fotowoltaicznych',
    descriptionPoints: [
      'Montaż instalacji fotowoltaicznych dla pozyskiwania energii elektrycznej ze światła słonecznego.',
      'Dobór i instalacja paneli fotowoltaicznych oraz inwerterów zapewniających efektywność systemu.',
      'Serwis i konserwacja instalacji fotowoltaicznych dla utrzymania ich optymalnej wydajności.',
    ],
    image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/mf.webp'
  },
  {
    title: 'Autoryzacyjny punkt serwisowy klimatyzacji i pomp ciepła firm: Rotenso, Aux, Sevra, Aiwa, Gree, Vasti',
    descriptionPoints: [
      'Serwis i konserwacja klimatyzacji i pomp ciepła dla utrzymania ich optymalnej wydajności.',
      'Specjalizada obsługa urządzeń firm: Rotenso, Aux, Sevra, Aiwa, Gree, Vasti.',
      'Profesjonalne doradztwo w zakresie eksploatacji i konserwacji klimatyzacji i pomp ciepła.',
    ],
    image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/ms.webp'
  }
  // Możesz dodawać kolejne usługi według potrzeb
];

  return (
    <div>
      {/* Inne elementy interfejsu użytkownika */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {servicesData.map((service, index) => (
        <ServiceComponent
          key={index}
          title={service.title}
          descriptionPoints={service.descriptionPoints}
          imageSrc={service.image}
        />
      ))}
      {/* Inne elementy interfejsu użytkownika */}
    </div>
  );
};

export default Montaz;