import React from 'react';
import styles from './CustomerRatings.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faStar } from '@fortawesome/free-solid-svg-icons';

const CustomerRating = ({ title, comment, rating, username, place }) => {
  return (
    <div className={styles.Rate}>
      <h2>{title}</h2>
      <p>"{comment}"</p>
      <br />
      <p>{rating}</p>
      <br />
      <p><FontAwesomeIcon icon={faUser} size="2x" /></p>
      <p><strong>{username}</strong></p>
      <p>{place}</p>
    </div>
  );
};

const CustomerRatings = () => {
  const ratingsData = [
    {
      title: 'Efektywny montaż pomp ciepła',
      comment: 'Montaż pompy ciepła przebiegł sprawnie i bez żadnych komplikacji. Zespół wykazał się profesjonalizmem i efektywnością, a pompy działają bez zarzutu. Zdecydowanie polecam!',
      rating: <><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></>,
      username: 'Michał Kwieciński',
      place: 'Połomia'
    },
    {
      title: 'Solidne przyłącza i usługi',
      comment: 'Skorzystałem z usług przyłączeniowych - woda, kanalizacja. Zespół wykazał się nie tylko sprawnością, ale także solidnością i rzetelnością. Z pełnym przekonaniem mogę polecić ich usługi.',
      rating: <><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></>,
      username: 'Maria Szymańska',
      place: 'Godów'
    },
    {
      title: 'Montaż kotłów i serwis na najwyższym poziomie',
      comment: 'Montaż kotłów gazowych przebiegł płynnie, a serwis okazał się być na najwyższym poziomie. Jakość obsługi i profesjonalizm zespołu spełnił moje oczekiwania.',
      rating: <><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></>,
      username: 'Adam Wójcik',
      place: 'Mszana'
    }
  ];

  return (
    <div className={styles.container}>
      {ratingsData.map((rating, index) => (
        <CustomerRating
          key={index}
          title={rating.title}
          comment={rating.comment}
          rating={rating.rating}
          username={rating.username}
          place={rating.place}
        />
      ))}
    </div>
  );
};

export default CustomerRatings;