// App.js
import React from 'react';


import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar'; // Dodaj import Navbar
import Home from './Page/HomePage';
import Przylacza from'./Page/PrzylaczaPage';
import Grzewcze from './Page/GrzewczePage';
import Techniczne from './Page/TechnicznePage';
import Montaz from './Page/MontazPage';
import ServicePage from './Page/ServicePage';
import ContactPage from './Page/ContactPage';
import Contact from './components/Contact';
import Footer from './components/Footer'; // Dodaj import Footer


import styles from './App.module.css';

const App = () => {
  return (
    <Router>
      <div class={styles.app}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/uslugi" element={<ServicePage />} />
          <Route path="/uslugi/przylacza" element={<Przylacza />} />
          <Route path="/uslugi/instalacje_grzewcze" element={<Grzewcze />} />
          <Route path="/uslugi/instalacje_techniczne" element={<Techniczne />} />
          <Route path="/uslugi/montaz_i_serwis" element={<Montaz />} />
          <Route path="/kontakt" element={<ContactPage />} />
        </Routes>
        <Contact />
        <Footer />
      </div>
    </Router>
  );
};

export default App;

