// Home.js
import React from 'react';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';

import Services from '../components/Services'
import CustomerRatings from '../components/CustomerRatings'
import About from '../components/About'
import CounterCustomer from '../components/CounterCustomer';
import WriteToUs from '../components/WriteToUs'
import Who from '../components/Who';
import Carousel from '../components/Carousel';

import styles from './HomePage.module.css';

const Home = () => {
  return (

      <div class={styles.Page}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
	      
        <Carousel />
        
        <br></br>
        <br></br>
        <CounterCustomer />
        <br></br>
        <br></br>

        <Services />
        <CookieConsent
        location="bottom"
        buttonText="Zgadzam się"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        enableDeclineButton
        declineButtonText="Odmawiam"
        onDecline={() => {
          Cookies.remove("myAwesomeCookieName2");
        }}
      >
        Ta strona używa plików cookie w celu ulepszenia jakości usług
      </CookieConsent>

        <Who />
        <br></br>
        <br></br>
        <br></br>

        <About />

        <br></br>
        <br></br>
        <CustomerRatings />
        <br></br>
        <br></br>

        <WriteToUs />

      </div>
  );
};

export default Home;
