import React from 'react';
import Services from '../components/Services';
const ServicePage = () => {


  return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Services />

    </div>
  );
};

export default ServicePage;