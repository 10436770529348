import React, { useState, useEffect } from 'react';
import styles from './CounterCustomer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faUsers, faHandshake, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Counter = () => {
  const [countJobs, setCountJobs] = useState(0);
  const [countYears, setCountYears] = useState(0);
  const [countClients, setCountClients] = useState(0);
  const [countPartners, setCountPartners] = useState(0);
  const [countWorkers, setCountWorkers] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countJobs < 3500) {
        setCountJobs(prevCount => prevCount + 1);
      }

      if (countClients < 2300) {
        setCountClients(prevCount => prevCount + 1);
      }

      if (countPartners < 3000) {
        setCountPartners(prevCount => prevCount + 1);
      }

      if (countYears < 2000) {
        setCountYears(prevCount => prevCount + 1);
      }

      if (countWorkers < 5000) {
        setCountWorkers(prevCount => prevCount + 1);
      }
    }, 1);

    return () => clearInterval(interval);
  }, [countJobs, countYears, countPartners, countWorkers, countClients]);

  return (
<div className={styles.counter}>
  <div className={styles.elementCounter}>
    <p className={styles.count}>{countJobs}</p>
    <p>WYKONANYCH ZLECEŃ</p>
    <p><FontAwesomeIcon icon={faCheckCircle} size="2x" /></p>
  </div>
  <div className={styles.elementCounter}>
    <p className={styles.count}>{Math.round(countClients / 10)}</p>
    <p>KLIENTÓW KTÓRZY NAM ZAUFALI</p>
    <p><FontAwesomeIcon icon={faHandshake} size="2x" /></p>
  </div>
  <div className={styles.elementCounter}>
    <p className={styles.count}>{Math.round(countPartners / 100)}</p>
    <p>PARTNERÓW BIZNESOWYCH</p>
    <p><FontAwesomeIcon icon={faUsers} size="2x" /></p>
  </div>
  <div className={styles.elementCounter}>
    <p className={styles.count}>{Math.round(countYears / 100)}</p>
    <p>LAT DOŚWIADCZENIA</p>
    <p><FontAwesomeIcon icon={faClock} size="2x" /></p>
  </div>
  <div className={styles.elementCounter} id={styles.centeredElement}>
    <p className={styles.count}>{Math.round(countWorkers / 1000)}</p>
    <p>PRACOWNIKÓW</p>
    <p><FontAwesomeIcon icon={faUser} size="2x" /></p>
  </div>
</div>
  );
};

export default Counter;