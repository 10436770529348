import React, { useState, useEffect } from 'react';
import styles from './Carousel.module.css';

const Carousel = () => {
  const slides = [
    {
      id: 1,
      text: 'Pełna oferta usług przyłączających!'.toUpperCase(),
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/c1.webp',
    },
    {
      id: 2,
      text: 'Zajmujemy się instalacjami wewnętrznymi!'.toUpperCase(),
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/c2.webp',
    },
    {
      id: 3,
      text: 'Montaż pomp ciepła, klimatyzacji i fotowoltaiki!'.toUpperCase(),
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/c3.webp',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [slides.length]);

  return (
    <div className={styles.carousel}>
      <div
        className={styles.slidesContainer}
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide) => (
          <div key={slide.id} className={styles.slide}>
            <img src={slide.image} alt={`Slide ${slide.id}`} />
            <p>{slide.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

