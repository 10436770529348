

import React from 'react';
import styles from './Who.module.css';

const Who = () => {

  return (
    <div class={styles.container}>
         <p class={styles.title}>KIM JESTEŚMY</p>
    </div>
  );
};

export default Who;