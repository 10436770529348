import React from 'react';
import ServiceComponent from '../components/ServiceComponent';


const Przylacza = () => {
  const servicesData = [
    {
      title: 'Przyłącza wodociągowe',
      descriptionPoints: [
        'Profesjonalne i bezpieczne podłączenia wody do budynków mieszkalnych i komercyjnych.',
        'Montaż i serwis instalacji wodociągowej, zgodnie z najwyższymi standardami jakości.',
        'Dobór i instalacja armatury wodociągowej dostosowanej do potrzeb klienta.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/pw.webp'
    },
    {
      title: 'Przyłącza kanalizacyjne',
      descriptionPoints: [
        'Profesjonalne i bezpieczne podłączenia do kanalizacji dla budynków mieszkalnych i komercyjnych.',
        'Montaż i serwis instalacji kanalizacyjnej, zgodnie z najwyższymi standardami jakości.',
        'Podłączenie do kanalizacji miejskiej lub montaż zbiornika ścieków.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/pk.webp'
    },
    {
      title: 'Przyłącza gazowe',
      descriptionPoints: [
        'Bezpieczne i profesjonalne podłączenia gazu do budynków mieszkalnych i komercyjnych.',
        'Montaż i serwis instalacji gazowej zgodnie z obowiązującymi normami bezpieczeństwa.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/pg.webp'
    },
    {
      title: 'Przyłącza elektryczne',
      descriptionPoints: [
        'Profesjonalne i bezpieczne podłączenia prądu do budynków mieszkalnych i komercyjnych.',
        'Montaż i serwis instalacji elektrycznej, zgodnie z najwyższymi standardami jakości.',
        'Podłączenie gniazd elektrycznych, oświetlenia, oraz systemów zabezpieczeń.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/pe.webp'
    },
    // Możesz dodawać kolejne usługi według potrzeb
  ];
  return (
    <div>
      {/* Inne elementy interfejsu użytkownika */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {servicesData.map((service, index) => (
        <ServiceComponent
          key={index}
          title={service.title}
          descriptionPoints={service.descriptionPoints}
          imageSrc={service.imageSrc}
        />
      ))}
      {/* Inne elementy interfejsu użytkownika */}
    </div>
  );
};

export default Przylacza;