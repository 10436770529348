// Contact.js
import React from 'react';
import styles from './Contact.module.css';
import MapWidget from './MapWidget';

const Contact = () => {
  return (
    <div>

    <section id="contact" className={styles.contact}>
      <h2 className={styles.contactHeader}>Kontakt</h2>
      <p className={styles.contactText}>
        Skontaktuj się z nami, aby uzyskać więcej informacji:
      </p>
      <p className={styles.contactInfo}>
        <span>Email:</span> grzegorz_skowron@wp.pl
      </p>
      <p className={styles.contactInfo}>
        <span>Telefon:</span> 791-821-114
      </p>
    </section>
    <MapWidget />
    </div>
  );
};

export default Contact;