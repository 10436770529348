import React from 'react';
import ServiceComponent from '../components/ServiceComponent';

const Techniczne = () => {
  const servicesData = [
    {
      title: 'Instalacje wodno-kanalizacyjne',
      descriptionPoints: [
        'Montaż i podłączenie instalacji wodociągowej oraz kanalizacyjnej w budynkach.',
        'Dobór i instalacja systemów rur i armatury zgodnych z obowiązującymi normami.',
        'Konserwacja i naprawa instalacji wodno-kanalizacyjnych dla utrzymania ich sprawności.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/iwk.webp'
    },
    {
      title: 'Instalacje gazowe',
      descriptionPoints: [
        'Montaż instalacji gazowej zgodny z najnowszymi standardami bezpieczeństwa.',
        'Podłączenie urządzeń gazowych, w tym kotłów gazowych i kuchenek gazowych.',
        'Konserwacja i serwis instalacji gazowych dla zachowania ich sprawności.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/ig.webp'
    },
    {
      title: 'Instalacje elektryczne',
      descriptionPoints: [
        'Montaż i podłączenie instalacji elektrycznej w budynkach mieszkalnych i komercyjnych.',
        'Dobór i montaż oświetlenia, gniazd elektrycznych, oraz systemów zabezpieczeń.',
        'Konserwacja i naprawa instalacji elektrycznych dla utrzymania ich sprawności.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/ie.webp'
    },
    {
      title: 'Monitoring',
      descriptionPoints: [
        'Instalacja systemów monitoringu wizyjnego dla zwiększenia bezpieczeństwa.',
        'Dobór kamer i urządzeń monitorujących dostosowanych do potrzeb klienta.',
        'Konserwacja i serwis systemów monitoringu dla utrzymania ich sprawności.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/im.webp'
    },
    {
      title: 'Wideodomofony',
      descriptionPoints: [
        'Montaż wideodomofonów do kontroli dostępu do budynków.',
        'Podłączenie i konfiguracja systemów wideodomofonowych.',
        'Serwis i naprawa wideodomofonów dla utrzymania ich prawidłowego działania.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/iwdm.webp'
    },
    {
      title: 'Bramy elektryczne',
      descriptionPoints: [
        'Montaż bram elektrycznych w celu zautomatyzowania dostępu.',
        'Podłączenie i konfiguracja systemów bram elektrycznych.',
        'Serwis i naprawa bram elektrycznych dla utrzymania ich sprawności.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/ibe.webp'
    },
    {
      title: 'Pomiary elektryczne okresowe oraz odbiorcze dla nowo wybudowanych instalacji elektrycznych',
      descriptionPoints: [
        'Wykonywanie pomiarów elektrycznych okresowych dla utrzymania bezpieczeństwa.',
        'Dokumentacja wyników pomiarów elektrycznych zgodnie z obowiązującymi przepisami.',
        'Naprawa usterek i nieprawidłowości wykrytych podczas pomiarów elektrycznych.',
      ],
      imageSrc: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/ipeo.webp'
    },
    // Dodawaj kolejne usługi według potrzeb
  ];
  
  return (
    <div>
      {/* Inne elementy interfejsu użytkownika */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {servicesData.map((service, index) => (
        <ServiceComponent
          key={index}
          title={service.title}
          descriptionPoints={service.descriptionPoints}
          imageSrc={service.imageSrc}
        />
      ))}
      {/* Inne elementy interfejsu użytkownika */}
    </div>
  );
};

export default Techniczne;