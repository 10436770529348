import React from 'react';

const ContactPage = () => {


  return (
    <div>


    </div>
  );
};

export default ContactPage;