import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(true); // Set to true initially

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      // Set the initial state of the submenu to open on smaller screens
      if (window.innerWidth <= 768) {
        setSubMenuOpen(true);
      } else {
        setSubMenuOpen(false);
      }
    };

    // Call handleResize on component mount and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={styles.nav}>
      <div className={styles.navContainer}>
        <a className={styles.logo} href="/">
          <span style={{ color: "#f8f8f8", borderBottom: "2px solid #ffd700" }}>Grzegorz</span>{" "}
          <span style={{ color: "#ffd700", borderBottom: "2px solid #f8f8f8" }}>SKOWRON</span> -{" "}
          <span style={{ color: "#f8f8f8", borderBottom: "2px solid #001f3f" }}>Instalacje</span>
        </a>
        <div className={`${styles.menuIcon} ${menuOpen ? styles.open : ''}`} onClick={toggleMenu}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
        <ul className={`${styles.navLinks} ${menuOpen ? styles.open : ''}`}>
          <li><a href="/">Strona Główna</a></li>
          <li className={`${styles.navServiceLink} ${subMenuOpen ? styles.open : ''}`} onClick={toggleSubMenu}>
            <a href="/uslugi">Usługi</a>
            <ul className={styles.subMenu}>
              <li><a href="/uslugi/przylacza">Przyłącza Zewnętrzne</a></li>
              <li><a href="/uslugi/instalacje_techniczne">Instalacje wewnętrzne</a></li>
              <li><a href="/uslugi/instalacje_grzewcze">Instalacje grzewcze</a></li>
              <li><a href="/uslugi/montaz_i_serwis">Montaż i serwis</a></li>
            </ul>
          </li>
          <li><a href="/kontakt">Kontakt</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
