import styles from './Footer.module.css';



const Footer = () => {

    return (
      <footer id="footer" class={styles.footers}>
        <p>2023 Instalacje Grzegorz Skowron. Wszelkie prawa zastrzeżone. </p>
      </footer>
    );
  };
  
  export default Footer;