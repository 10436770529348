// Services.js
import React from 'react';
import styles from './Services.module.css';

const Services = () => {
  const serviceItems = [
    {
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/kpz.webp',
      title: 'Przyłącza zewnętrzne',
      shortcut: 'przylacza',
      description: 'Wykonujemy przyłącza zewnętrzne do domów jednorodzinnych oraz wielorodzinnych:',
      subServices: ['Wodociągowe', 'Kanalizacyjne', 'Gazowe', 'Elektryczne']
    },
    {
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/kiw.webp',
      title: 'Instalacje wewnętrzne',
      shortcut: 'instalacje_techniczne',
      description: 'Nasze usługi obejmują:',
      subServices: ['Instalacje wodno-kanalizacyjne', 'Instalacje gazowe', 'Instalacje elektryczne' , 'Monitoring' , 'Wideodomofony' , 'Bramy elektryczne', 'Wykonywanie pomariów elektrycznych okresowych']
    },
    {
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/kig.webp',
      title: 'Instalacje grzewcze',
      shortcut: 'instalacje_grzewcze',
      description: 'Specjalizujemy się w montażu różnych typów instalacji grzewczych:',
      subServices: ['Kotły gazowe', 'Kotły paletowe', 'Kotły węglowe', 'Zbiorniki buforowe', 'Grzejniki', 'Ogrzewanie podłogowe']
    },
    {
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/km.webp',
      title: 'Montaż i serwis',
      shortcut: 'montaz_i_serwis',
      description: 'Oferujemy profesjonalny montaż oraz regularny serwis:',
      subServices: ['Pompy ciepła', 'Systemów klimatyzacji', 'Instalacji fotowoltaicznych', 'Autoryzacyjny punkt serwisowy klimatyzacji i pomp ciepła firm: Rotenso, Aux, Sevra, Aiwa, Gree, Vasti']
    }
  ];

  return (
    <div className={styles.service}>
      <header>
        <h2 className={styles.title}>NASZE USŁUGI</h2>
      </header>
      <section className={`${styles.service} ${styles.services}`} id="services">
        <div className={styles.serviceItems}>
          {serviceItems.map((item, index) => (
            <div
              onClick={() => window.location.href = '/uslugi/' + item.shortcut}
              key={index}
              className={styles.serviceItem}
              style={{ flexBasis: '20%', marginRight: '10px', cursor: 'pointer' }}
            >
              <img 
                src={item.image}
                alt={item.title}
                style={{ width: '100%', height: 'auto' }}
              />
              <h3 className={styles.serviceName}>{item.title}</h3>
              <p>{item.description}</p>
              <ul className={styles.subServices}>
                {item.subServices.map((subService, subIndex) => (
                  <li key={subIndex}>{subService}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Services;
