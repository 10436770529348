import React from 'react';

const GoogleMap = () => {
  return (
    <iframe
      title="Google Map"
      style={{ width: '100%', height: '480px' }}  // Ustaw szerokość na 100%
      frameBorder="0"
      allowFullScreen=""
      src="https://www.google.com/maps/d/u/0/embed?mid=1BOOsfzuTedIKoX14-39x-m1FDPxYRw0&ehbc=2E312F&noprof=1"
    />
  );
};

export default GoogleMap;