import React from 'react';
import styles from './About.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faComments,
  faLightbulb,
  faShield,
  faMoneyBill,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

const ServiceWidget = ({ title, icon, description }) => {
  return (
    <div className={styles.ServiceWidget}>
      <h2>{title}</h2>
      <FontAwesomeIcon icon={icon} size="2x" />
      <p>{description}</p>
    </div>
  );
};

const About = () => {
  const widgetsData = [
    {
      title: '20+ Lat Doświadczenia',
      icon: faLightbulb,
      description: 'Nasze usługi są oparte na ponad 20 latach doświadczenia w branży. Możesz polegać na naszej wiedzy i umiejętnościach, niezależnie od tego, czy potrzebujesz usług elektryka, hydraulika czy specjalisty ds. kanalizacji.',
    },
    {
      title: 'Fachowe Doradzctwo',
      icon: faComments,
      description: 'Zespół naszych ekspertów oferuje fachowe doradztwo techniczne, przygotowuje ekspertyzy elektryczne oraz służy pomocą w doborze odpowiedniego sprzętu. Naszym celem jest zapewnić Ci pełne wsparcie w realizacji Twojego projektu.',
    },
    {
      title: 'Szybkie Terminy',
      icon: faCalendar,
      description: 'Jesteśmy świadomi, jak istotne jest szybkie działanie w przypadku usług elektrycznych, hydraulicznych i kanalizacyjnych. Dlatego zapewniamy błyskawiczne terminy realizacji na terenie śląska. Twój komfort i bezpieczeństwo są dla nas priorytetem.',
    },
    {
      title: 'Udzielamy Gwarancji',
      icon: faShield,
      description: 'Jako profesjonalni wykonawcy, udzielamy gwarancji na wszystkie nasze prace elektryczne, hydrauliczne i kanalizacyjne, a także na zastosowane materiały. Masz pewność, że nasza praca jest solidna i trwała.',
    },
    {
      title: 'Atrakcyjne Ceny',
      icon: faMoneyBill,
      description: 'Rozumiemy różnorodność potrzeb i budżetów naszych klientów. Dlatego oferujemy elastyczny i atrakcyjny cennik usług elektrycznych, hydraulicznych i kanalizacyjnych, dostosowany do każdej kieszeni.',
    },
    {
      title: 'Bezpłatna Wycena',
      icon: faDollarSign,
      description: 'Zawsze jesteśmy gotowi dostarczyć bezpłatne wyceny i fachowe doradztwo w zakresie usług elektrycznych, hydraulicznych i kanalizacyjnych. Jeśli potrzebujesz pomocy w rozważeniu rozwiązań dla swojego projektu, jesteśmy tu, aby Ci pomóc.',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.allWidgets}>
        {widgetsData.map((widget, index) => (
          <ServiceWidget
            key={index}
            title={widget.title}
            icon={widget.icon}
            description={widget.description}
          />
        ))}
      </div>
    </div>
  );
};

export default About;