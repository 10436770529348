import React from 'react';
import styles from './WriteToUs.module.css';

const WriteToUs = () => {

  return (
    
<div class={styles.contactinfo}>
  <p class={styles.textToUser}>Dla nas nie ma rzeczy niemożliwych, <span class={styles.call}>ZADZWOŃ!</span></p>
  <p class={styles.textToUser}><span class={styles.phone}>791-821-114</span></p>
  <br></br>
</div>
  );
};

export default WriteToUs;