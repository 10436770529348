import React from 'react';
import ServiceComponent from '../components/ServiceComponent';

const Grzewcze = () => {
  const servicesData = [
    {
      title: 'Kotły gazowe',
      descriptionPoints: [
        'Specjalizujemy się w montażu kotłów gazowych, zawsze zgodnie z najnowszymi standardami bezpieczeństwa.', 
        'Pomagamy w doborze kotła gazowego, który najlepiej odpowiada Twoim potrzebom i specyfice miejsca.',
        'Oferujemy regularną konserwację i naprawę kotłów gazowych, aby zapewnić ich długotrwałe i bezproblemowe działanie.',
      ],
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/gkg.webp',
    },
    {
      title: 'Kotły paletowe',
      descriptionPoints: [
        'Montujemy kotły na pelety, zawsze z uwzględnieniem najnowszych standardów bezpieczeństwa.',
        'Dobieramy kotły na pelety, które najlepiej odpowiadają Twoim wymaganiom i specyfice miejsca.',
        'Zapewniamy regularną obsługę, konserwację i naprawę kotłów na pelety, aby służyły Ci jak najdłużej.', 
      ],
      image:'https://imageselektryk.s3.eu-central-1.amazonaws.com/gkp.webp'
    },
    {
      title: 'Kotły węglowe',
      descriptionPoints: [
        'Montujemy kotły węglowe zgodnie z obowiązującymi normami bezpieczeństwa.',
        'Dobieramy kotły węglowe, które są dostosowane do Twoich potrzeb i specyfiki miejsca.',
        'Oferujemy regularną konserwację i naprawę kotłów węglowych, aby zapewnić ich niezawodność.',
      ],
      image:'https://imageselektryk.s3.eu-central-1.amazonaws.com/gkw.webp'
    },
    {
      title: 'Zbiorniki buforowe',
      descriptionPoints: [
        'Montujemy zbiorniki buforowe, które pomagają zwiększyć efektywność Twojego systemu grzewczego.',
        'Dobieramy zbiorniki buforowe, które są zgodne z Twoimi potrzebami i specyfiką miejsca.',
        'Zapewniamy regularną konserwację i naprawę kotłów buforowych, aby zawsze działały na najwyższych obrotach.',
      ],
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/gzb.webp'
    },
    {
      title: 'Grzejniki',
      descriptionPoints: [
        'Montujemy grzejniki w budynkach mieszkalnych i komercyjnych, zawsze zgodnie z Twoimi oczekiwaniami.',
        'Dobieramy grzejniki, które najlepiej pasują do Twoich potrzeb i specyfiki miejsca.',
        'Oferujemy regularną konserwację i naprawę grzejników, aby zawsze były w pełni sprawne.',
      ],
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/gg.webp'
    },
    {
      title: 'Ogrzewanie podłogowe',
      descriptionPoints: [
        'Montujemy systemy ogrzewania podłogowego w budynkach mieszkalnych i komercyjnych, zawsze zgodnie z Twoimi oczekiwaniami.',
        'Dobieramy systemy ogrzewania podłogowego, które są zgodne z Twoimi potrzebami i specyfiką miejsca.',
        'Zapewniamy regularną konserwację i naprawę systemów ogrzewania podłogowego, aby zawsze były w pełni sprawne.',
      ],
      image: 'https://imageselektryk.s3.eu-central-1.amazonaws.com/gop.webp'
    },
  ];

  return (
    <div>
      {/* Inne elementy interfejsu użytkownika */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {servicesData.map((service, index) => (
        <ServiceComponent
          key={index}
          title={service.title}
          descriptionPoints={service.descriptionPoints}
          imageSrc={service.image}
        />
      ))}
      {/* Inne elementy interfejsu użytkownika */}
    </div>
  );
};

export default Grzewcze;